/*
Naming Schema :
Component Outer Class
inner class Kebab Case
all lowercase with - separating words.
//$CalcPanels-nav-button-background-color_active:  #000000;

Include  this any componets scss file
@import "src/client_customizations/scss/client_master.scss";

*/

$darkBlue: #00587c;
$green:#1e7c32;
$blue:#0f6b9d;
$yellow:#ffd72d;
$darkGray:#333333;
$gray: #505357;
$red: #23BEC6;

$primary-color:  rgb(153, 91, 0);
$twentyfive-px-border-radius: 25px;
$focus-box-shadow: 0 0 0 4px $red;
$span-border-active: 4px solid $red;



/*
    Header
*/

$Header-background-color:#fff;
$Header-border-bottom: 5px solid #f3f4f4;
$Header-link-color:#465A65;
$Header-navbar-link-color:$red;
$Header-navbar-toggler-border-color:$red;
$Header-navbar-toggler-color:$red;

/*
    Main
*/

$Main-min-height:500px;

/*
Page Layout
*/

$PageLayout-header-bold-color:#666666;
$PageLayout-link-focus-box-shadow:$focus-box-shadow;
$PageLayout-button-focus-box-shadow:$focus-box-shadow;
$PageLayout-form-control-focus-box-shadow:$focus-box-shadow;
$PageLayout-input-focus-box-shadow:$focus-box-shadow;
$PageLayout-select-focus-box-shadow:$focus-box-shadow;
$PageLayout-textarea-focus-box-shadow:$focus-box-shadow;
$PageLayout-image-gallery-thumbnail-focus-box-shadow: $focus-box-shadow;
$PageLayout-SimpleCopyPageBody-card-focus-box-shadow:$focus-box-shadow;
$PageLayout-SimpleCopyPageBody-card-background-color:white;
$PageLayout-SimpleCopyPageBody-card-link-hover-color:$darkGray;
$PageLayout-SimpleCopyPageBody-card-header-color:$darkGray;
$PageLayout-evc-card-focus-box-shadow:white;
$PageLayout-evc-card-background-color:$darkGray;
$PageLayout-evc-card-font-size:14px;
$PageLayout-evc-card-border:1px solid #dbdbdb;
$PageLayout-evc-card-color:$darkGray;
$PageLayout-evc-card-hover-border:1px solid white;
$PageLayout-evc-card-hover-box-shadow:0 3px 10px 0 rgba(0, 0, 0, 0.25);
$PageLayout-evc-card-hover-color:$darkGray;
$PageLayout-socialmedia-focus-box-shadow:$focus-box-shadow;
$PageLayout-faq-focus-box-shadow:$focus-box-shadow;
$PageLayout-faq-focus-color:inherit;
$PageLayout-container-background-color:#fff;
$PageLayout-legal-disclaimer-color:#666666;
$PageLayout-header-strong-color:$red;
$PageLayout-SimpleCopy-color:$darkGray;
$PageLayout-evc-card-hover-border:1px solid white;
$PageLayout-evc-card-hover-box-shadow:0 3px 10px 0 rgba(0, 0, 0, 0.25);
$PageLayout-evc-card-hover-color:$darkGray;


/*
    Generic Footer
*/

$GenericFooter-background-color:$darkGray;
$GenericFooter-color:$darkGray;
$GenericFooter-heading-color:#a9a9a9;
$GenericFooter-heading-font-size:18px;
$GenericFooter-links-font-size:13px;
$GenericFooter-links-line-height:25px;
$GenericFooter-links-color:#a9a9a9;
$GenericFooter-links-hover-color:#fff;


/*
    CalcPanels
*/
$CalcPanels-nav-button-font-color: #000000;
$CalcPanels-nav-button-font-color_active: white;
$CalcPanels-nav-button-background-color: transparent;
$CalcPanels-nav-button-background-color_active:  #000000;
$CalcPanels-nav-button-border_color_active: #000000;
$CalcPanels-font-size: 14px;
$CalcPanels-nav-button-font-size: 16px;

/*
    EVCard
*/

$EVCard-EVCardBottom-border:1px solid #dbdbdb;


/*
    PricePanels
*/

$PricePanels-background-color: white;
$PricePanels-border: 1px solid #dbdbdb;
$PricePanels-Nav-border: 1px solid #dbdbdb;
$PricePanels-li-a-font-weight: 500;
$PricePanels-li-a-font-color: rgb(33, 37, 41);
$PricePanels-li-a-font-size: 16px;
$PricePanels-li-a-span-border_active:6px solid #23BEC6;
$PricePanels-nav-pills-nav-link-background-color_active: inherit;
$PricePanels-nav-pills-nav-link-font-color_active:  rgb(33, 37, 41);
$PricePanels-tab-pane-PanelItem-border: 1px #dbdbdb solid;
$MatchScoreBadge-background-color: white;
$MatchScoreBadge-border: 1px solid #dbdbdb;


/*
    Incentive Card
*/

$IncentiveCard-card-border-radius: 0px;
$IncentiveCard-card-body-color: #000;
$IncentiveCard-card-title-color: $red;
/*
    Incentive Preferences
*/

$IncentivePreferences-input-well-background-color:transparent;


/*
    Link Card
*/

$LinkCard-card-hover-body-color:#00527c;
$LinkCard-evc-card-background-color:#f2f2f2;


/*
    Charging Station
*/

$ChargingStation-card-background-color:white;

/*
    Dealer Location Card
*/

$DealerLocationCard-background-color:white;
$DealerLocationCard-border-radius:4px;
$DealerLocationCard-border:1px solid #dbdbdb;


/*
    ChargingMap
*/

$ChargingMap-map-container-border:1px solid #ccc;
$ChargingMap-legend-background:#fff;
$ChargingMap-legend-border:1px solid #ccc;
$ChargingMap-legend-border-radius:4px;
$ChargingMap-legend-font-size:0.75rem;
$ChargingMap-dealer-map-container-border:1px solid #ccc;
$ChargingMap-dealer-map-container-border-radius:4px;
$ChargingMap-dealer-legend-background:#fff;
$ChargingMap-dealer-legend-border:1px solid #ccc;
$ChargingMap-dealer-legend-border-radius:4px;


/*
    Render Item
*/

$RenderItem-title-color:#666666;
$RenderItem-item-value-color:#333;
$RenderItem-item-value-denominator-color:#252525;
$RenderItem-match-score-tr-last-child-border-top:1px solid #6f6f6f;
$RenderItem-match-score-tr-last-child-border-bottom:1px solid #6f6f6f;
$RenderItem-match-score-tr-th-color:#252525;


/*
    Button Group
*/

$ButtonGroup-btn-background-color:#f2f2f2;
$ButtonGroup-btn-hover-background-color:#23BEC6;
$ButtonGroup-btn-active-background-color:#23BEC6;


/*
    Input Range
*/

$InputRange-border:1px solid white;
$InputRange-runnable-track-background:#ddd;
$InputRange-slider-thumb-border-radius:50%;
$InputRange-slider-thumb-background:#23BEC6;
$InputRange-focus-runnable-track-background:#ccc;
$InputRange-fill-lower-background:#777;
$InputRange-fill-lower-radius:10px;
$InputRange-fill-upper-background:#ddd;
$InputRange-fill-upper-radius:10px;
$InputRange-ms-thumb-radius:50%;
$InputRange-ms-thumb-background:#23BEC6;
$InputRange-ms-fill-lower:#888;
$InputRange-ms-fill-upper:#ccc;


/*
    Pages: Homepage
*/

$HomePage-big-promise-padding:120px 40px;
$HomePage-big-promise-background-color:rgba(0, 0, 0, 0.5);
$HomePage-big-promise-color:rgba(255, 255, 255, 0.9);
$HomePage-big-promise-text-shadow:2px 2px 3px rgba(255, 255, 255, 0.1);
$HomePage-big-promise-min-height:400px;
$RenderItem-match-score-value-greaterThanEightyFive-color: $green;
$RenderItem-match-score-value-greaterThanSeventy-color: $yellow;
$RenderItem-match-score-value-lessThanSeventy-color: $darkGray;
/*
    Pages: EVs
*/

$EVsSort-label-font-weight:bold;
$EVsSort-label-font-size:12px;
$EVsSort-label-color:#666;
$EVsSort-label-letter-spacing:0.5px;
$EVsSort-label-line-height:20px;
$EVsSort-select-background:#ffffff;
$EVsSort-select-border:1px solid #dbdbdb;
$EVsSort-select-border-radius:4px;
$EVsSort-select-font-size:14px;
$EVsSort-select-color:#666666;
$EVsSort-select-letter-spacing:0;

/* Pages Compare Vehicles */
$CompareVehicles-ViewVehicle-button-color: $darkBlue;
$CompareVehicles-ViewVehicle-button-fontcolor: #ffffff;
/* Pages Compare Vehicles */
$CompareVehicleToggle-MobileToggle-button-color: $darkBlue;
$CompareVehicleToggle-MobileToggle-button-fontcolor: #ffffff;

$CompareVehicleToggle-MobileToggle-button-fontcolor-active:#ffffff;
$CompareVehicleToggle-MobileToggle-button-color-active: $darkGray;



.MatchScoreOptions, .cost-of-ownership-options {
    margin-top:50px;

    @media(max-width:768px) {
        margin-top:0px;
    }

    p.h2 {
        margin-bottom:30px;
    }

    .form-group {
        margin-bottom: 20px !important;
        label {
            text-transform: none;
            color:#000;
            margin:0 0 15px;
        }

        p.mb-0 {
            margin-top:15px;
        }
    }

    .btn {
        background-color:transparent;
        border:none;
        text-transform: none;
        color:#23BEC6;
        font-weight: bold;
        padding:0;

        &:after {
            content: "\203A";
            font-size: 16px;
            margin-left: 5px;
        }
    }
}

.assumptions-area {
    p.RenderItemTitle {
        color:#000;
        font-weight: bold;
    }

    p.legal-disclaimer {
        font-size:12px;
    
        span {
            color:#23BEC6;
            font-weight:bold;

            a {
                color:#23BEC6;
                font-weight:bold;
            }
        }
    }
}

#EVIncentives {
    padding:100px 0;
}

#CostOfOwnership {
    background-color: #F2F2F2;
    padding:100px 0;
}
.CostOfOwnership {
    p.h1 {
        margin:0 0 50px;
    }
}

.graph-sub-title {
    font-size:16px;
}

.input-well {
    .show-full-list-container {
        margin-top:25px;
    }
}

#EVIncentives {
    p.h1 {
        margin:25px 0 0;
    }
}

.cost-of-ownership-options {
    margin-top:0 !important;
}

#charging-map-ev {
    padding:100px 0;
    background-color:#F2F2F2;

    h1 {
        text-align: center;
        font-size:30px;
        margin:0 0 30px;
    }

    .ChargingMap {
        background-color:#fff;
        padding:20px;
        border: 1px solid #DCDCDC;
        border-radius: 5px;
    }
}

#charging-map-section {
    padding:100px 0;

    h1 {
        text-align: center;
        font-size:30px;
        margin:0 0 30px;
    }

    .tabbed-maps-panel {
        ul.d-none {
            display:none !important;
        }
    }
}

.ChargingMap {
    .MapControlPanel {
        .map-controls {
            .form-group {
                label {
                    color:#465A65;
                    font-weight: bold;
                }

                .input-group {
                    position: relative;

                    #input-zipcode-for-charging-map {
                        border-top-right-radius: 0.25rem !important;
                        border-bottom-right-radius: 0.25rem !important;
                        padding-right:40px;
                    }

                    #update-zipcode-button {
                        width:32px;
                        height:32px;
                        position: absolute;
                        top:3px;
                        right:3px;
                        text-indent: -9999px;
                        border-radius: 3px;
                        z-index: 99;

                        &:after {
                            content:"";
                            width:25px;
                            height:25px;
                            position: absolute;
                            display:block;
                            top:3px;
                            left:3px;
                            background-repeat: no-repeat;
                            background-size:25px;
                            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMS44NTAzIDIwLjM5NDJMMTcuMTkyOSAxNS43MDI0QzE3LjAxODEgMTUuNTI2NiAxNy4wMDA2IDE1LjI1NTUgMTcuMTM1OSAxNS4wNDc4QzE5LjA0NTQgMTIuMTE4OSAxOC42IDguMDkxMSAxNS43ODI4IDUuNjg1M0MxMy4wNzIgMy4zNzEwNCA4Ljk0NTkxIDMuNDUwMjQgNi4zMTk0NyA1Ljg2MTE4QzMuMzA0NzQgOC42Mjg1MyAzLjIyODYzIDEzLjMzODggNi4wOTIxNSAxNi4yMDQ0QzguNTMzOTcgMTguNjQ4MiAxMi4zMTM0IDE4Ljk0NSAxNS4wODkgMTcuMTA5QzE1LjI5NzMgMTYuOTcxMiAxNS41NzA0IDE2Ljk4OTIgMTUuNzQ2MyAxNy4xNjYxTDIwLjM5MzMgMjEuODQ4MUMyMC41OTQ0IDIyLjA1MDIgMjAuOTIwNSAyMi4wNTA3IDIxLjEyMjEgMjEuODQ5MUwyMS44NDkzIDIxLjEyMTRDMjIuMDQ5OCAyMC45MjA4IDIyLjA1MDMgMjAuNTk1OCAyMS44NTAzIDIwLjM5NDJaTTcuNTQ2NTQgMTQuNzQ4NUM1LjU1OTM2IDEyLjc1OTcgNS41NTkzNiA5LjUyMzg5IDcuNTQ2NTQgNy41MzUxN0M5LjUzMzczIDUuNTQ2NDUgMTIuNzY3IDUuNTQ1OTMgMTQuNzU0MiA3LjUzNTE3QzE2Ljc0MTQgOS41MjM4OSAxNi43NDE0IDEyLjc1OTcgMTQuNzU0MiAxNC43NDg1QzEyLjc2NyAxNi43MzcyIDkuNTMzNzMgMTYuNzM3MiA3LjU0NjU0IDE0Ljc0ODVaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K")
                        }
                    }
                }
            }
        }
    }
}

.input-well {
    .btn-grid-container {
        .btn {
            background-color:#fff;
            color:#23BEC6;
            font-weight: bold;
        }
    }
}

.filter-switch {
    span.badge {
        img {
            width:25px;
        }
    }

    .switch {
        float:right;
    }

    .clearfix {
        clear:both;
    }
}

#ev-jumbotron-title {
    padding:50px 0;
    background-color:#f0f0f0;

    h1 {
        font-size:30px;
    }
}

#incentives-page {
    padding:50px 0;

    h1 {
        text-align: center;
        font-size:30px;
    }

    p.lead {
        max-width: 600px;
        text-align: center;
        margin:0 auto;
    }

    .btn {
        margin: 25px auto 20px !important;
        display: block;
        max-width: 280px;
        padding: 10px 30px;
        border-radius: 3px;
    }
}

.price-panel-links {
    display:flex;
    justify-content: space-between;

    .mfg-btn {
        position: relative;
        background-color: #fff;
        color:#465A65 !important;
        border:2px solid #465A65 !important;
        border-radius: 5px;
        min-width: 120px;
        padding-right:25px;

        &:after {
            content:"";
            position: absolute;
            width:15px;
            height:15px;
            top:8px;
            right:10px;
            background-size:15px;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4IDE4SDJWMkgxMFYwSDBWMjBIMjBWMTBIMThWMThaIiBmaWxsPSIjNDY1QTY1Ii8+CjxwYXRoIGQ9Ik0xMi4wMDAzIDBMMTQuODgwMyAyLjg4TDkuMjEwOTQgOC41NDkzM0wxMS40NTA5IDEwLjc4OTNMMTcuMTIwMyA1LjEyTDIwLjAwMDMgOFYwSDEyLjAwMDNaIiBmaWxsPSIjNDY1QTY1Ii8+Cjwvc3ZnPgo=")
        }
    }

    .btn-ae {
        background-color: #fff;
        color:#23BEC6;
        border:2px solid #23BEC6;
        border-radius: 5px;
        min-width: 120px;
    }
}

.show-full-list-container {
    .btn {
        background-color: #fff;
        color:#23BEC6;
        border:2px solid #23BEC6 !important;
        font-weight:bold !important;
    }
}

.CompareVehicles {
    .text-center {
        h2 {
            font-size:30px;
            text-align:center;
        }

        a {
            color:#23BEC6;
        }
    }
}


/* Maps */
.google-map-cluster-marker {
    width: 40px;
    height: 52px;
    color: #000;
    // background: #1978c8;
    background-image: 
        url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 80.555 100' enable-background='new 0 0 80.555 100' xml:space='preserve'><path d='M76.389,26.389V18.75c0-1.15-0.934-2.083-2.084-2.083c-1.149,0-2.083,0.933-2.083,2.083v7.639h-8.333V18.75  c0-1.15-0.934-2.083-2.084-2.083c-1.149,0-2.083,0.933-2.083,2.083v7.639h-4.167v4.167c0,5.947,4.157,10.915,9.723,12.18v15.598  l4.156,30.662c-0.059,3.014-2.518,5.449-5.545,5.449c-3.062,0-5.553-2.489-5.556-5.55h0.001V50.005L58.333,50  c0-4.595-3.738-8.333-8.333-8.333h-8.334V8.102C41.667,3.634,37.929,0,33.333,0h-25C3.738,0,0,3.634,0,8.102V100h41.667V47.222H50  c1.531,0,2.777,1.247,2.777,2.778l0.001,0.005v38.883h-0.001c0,6.127,4.984,11.111,11.111,11.111C70.015,100,75,95.016,75,88.889  l-4.167-30.744V42.735c5.565-1.265,9.722-6.232,9.722-12.18v-4.167H76.389z M10.524,73.299l5.983-11.965H9.028l10.47-17.95h7.478  L19.498,55.35h8.974L10.524,73.299z M36.111,29.398c0,1.404-1.247,2.546-2.778,2.546h-25c-1.531,0-2.778-1.142-2.778-2.546V8.102  c0-1.404,1.247-2.546,2.778-2.546h25c1.531,0,2.778,1.142,2.778,2.546V29.398z'/></svg>");
    border-radius: 10%;
    padding: 4px;
    display: flex;
    justify-content: left;
    align-items: top;

    .text{
        background-color: #fff;
        height: 12px;    
    }
}
.google-map-location-marker-button {
    background-color: transparent;
    border: none;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 17px;
     text-decoration: none;
    text-shadow: 0px 1px 0px #2f6627;
    :hover {
        background-color: transparent;
    }
    :active {
        position: relative;
        top: 1px;
    }
    :hover {
        animation-duration: 2s;
        animation-iteration-count: infinite;
        z-index: 9999;

    }
    :focus {
        outline: none;
    }
}
