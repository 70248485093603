.MapControlPanel {
  display: flex;
  flex-direction: column;
  height: 100%;

  .map-controls {
    flex-grow: 1;
  }

  .SelectedChargingStationCard {
    margin-bottom: 10px;
  }

  .form-check label {
    font-weight: 700;
    font-size: 12px;
    color: #666666;
    text-transform: uppercase;
    margin-bottom: 0;

  }
  .charging-map-disclaimer {
    padding: 0;
    text-align: center;;
  }
}
