@import "src/client_customizations/scss/client_master.scss";
.Footer {
	background-color: #465A65;
	padding:50px 0;
	color: $GenericFooter-color;

	.social-links, .footer-links {
		list-style-type: none;
		width:100%;
		text-align: center;
		
		li {
			display: inline-block;
		}
	}
	.disclaimer_container {
		justify-content: center;
		text-align: center;
		// margin-top: 10px;
		// padding-left: 0px;
		font-family: Arial, Helvetica;
		font-size: 10px; /* Set the font size to 10px */
		div {
			   background-color:transparent !important;
	
			   img {
					  max-width: 50%;
					  margin:0 0 25px;
			   }
	
			   p {
					  color:white;
			   }
		}
	}


	.social-links {
		li {
			margin-right:35px;

			&:last-child {
				margin-right:0px;
			}
		}
	}

	.footer-links {
		margin:30px 0;
		li {

			@media(max-width:400px) {
				display:block;

				a {
					border-right:none !important;
					padding:0;
				}
			}

			&:last-child {
				a {
					border-right:none;
					padding-right:0;
				}
			}

			a {
				color:#fff;
				padding:0 10px;
				line-height: 1em;
				border-right:1px solid #fff;
			}
		}
	}

	.h3 {
		margin-bottom: 5px;
		font-size: $GenericFooter-heading-font-size;
		color: $GenericFooter-heading-color;
	}
	a {
		transition: 0.3s all ease;
		-webkit-transition: 0.3s all ease;
		font-size: $GenericFooter-links-font-size;
		line-height: $GenericFooter-links-line-height;
		color: $GenericFooter-links-color;
		&:hover {
			color: $GenericFooter-links-hover-color;
			padding-left: 5px;
			text-decoration: underline;
		}
	}
	.p {
		color: $GenericFooter-links-color;
	}

	p {
		&.copyright {
			width:100%;
			text-align: center;
		}
	}
}