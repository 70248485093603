@import "src/client_customizations/scss/client_master.scss"; 

.CompareVehicles {
		// padding-top:40px;

		#compare-header {
			background-color:#F2F2F2;
			padding:50px 0 350px;
			margin-bottom:-350px;
		}

		section.grey {
			background-color:#F2F2F2;

			.container {
				background-color: transparent;
			}
		}

		.compared-vehicle-container {
			padding-top: 20px;
		}

		.single-vehicle {
			padding-top: 50px;
			padding-bottom: 50px;
		}
}