@import "src/client_customizations/scss/client_master.scss";

.sidebar-schedule-test-drive {
    position: fixed;
    position: sticky;
    top: 40%;
    transform: rotate(270deg);
      transform-origin: left top 0;
    height: 0;
    z-index: 10;

  .test-drive-button {
    background-color: rgb(35, 190, 198);
    color: #fff;
    padding: 0.25em 1.75em;
    font-size: 18px;
    border: none;
    a {
      color: #fff;
      text-decoration: none;
      font-weight: bold;
    }
  }
}

@media (max-width: 991px) {
  .sidebar-schedule-test-drive {
    top: calc(100% - 30px - 112px);
    .test-drive-button {
      background-color: rgba(35, 190, 198, .7);
    }
  }
}
