@import "src/client_customizations/scss/client_master.scss";

.IncentiveCard {
	display:flex;
	flex-wrap: wrap;
   	justify-content: center;
	flex-basis: 100%;
	margin-right: 1%;
	margin-bottom: 1%;
	border-radius: $IncentiveCard-card-border-radius;
	border-top:9px solid #285C4D;
	margin-bottom:50px;

	&:hover {
		border-top:9px solid #285C4D !important;
	}

	hr {
		border-top: 2px solid #DADADA;
	}
}
.IncentiveCardBody {
	min-height:125px;
	align-self: flex-start;
	padding: 1rem;
	text-align: center;
	color: $IncentiveCard-card-body-color;
	border-radius: 0;
	border-bottom:1px solid #ccc;

	p.h6 {
		font-weight: 600;
	}

	.card-title {
		margin-top: 1rem;
		color: #333333;
		font-weight: bold;
		font-size:30px;
	}
	img {
		margin-top: -12px;
		margin-bottom: -16px;
		width: 60px;
	}
}

.divider {
	align-self: flex-start;
	width:100%;
	height:1px;

	hr {
		width:100%;
		height:1px;
	}
}

.IncentiveCardBottom {
	align-self: flex-start;
	padding: 1rem;
	text-align: left;
}

.IncentiveCardFlag {
	width:100%;
	align-self: flex-end;

	img {
		display:block;
		margin:0 auto;
		padding-bottom:25px;
		max-width: 50px;
	}
}

@media (min-width: 768px) {
	.IncentiveCard {
		flex-basis: 24%;
		margin-bottom:25px;
	}
	.IncentiveCardBody {
		padding: 1rem;
	}
	.IncentiveCardBottom {
		padding: 1rem;
	}
}
