@import "src/client_customizations/scss/client_master.scss";

.link-card-container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
	max-width: 600px;
	margin: 0 auto;
}
.link-card {
	display: flex;
	text-align: center;
	flex-shrink: 1;
	flex-basis: 80px;
	cursor: pointer;

	img {
		width: 150px;
	}
	p {
		margin-top: 0.5rem;
		margin-bottom: 0;
	}
	&:hover {
		p {
			text-decoration: none;
			color: $LinkCard-card-hover-body-color;
		}
	}
	.evc-card {
		background-color: transparent;
		border: none;
		// box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
		padding: 2rem 1.5rem 1rem 1.5rem;
		border-radius: 0px;
		p {
			font-size: 1.2em;
			font-weight: bold;
			text-transform: none;
		}
		&:hover {
			background-color: #fff;
			box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.15);
		}
	}
}
// @media (min-width: 576px) {
// 	.link-card-container {
// 		max-width: 600px;
// 	}
// 	.link-card {
// 		flex-basis: 120px;
// 		img {
// 			// width: 50px;
// 		}
// 		.evc-card {
// 			p {
// 				// font-size: 0.85rem;
// 			}
// 			// padding: 1rem;
// 		}
// 	}
// }
@media (max-width: 768px) {
	.link-card {
		flex-basis: 250px;
	}

	.link-card-container {
		max-width: none;
		flex-direction: column;
	}
}
