@import "src/client_customizations/scss/client_master.scss";

section.container.homepage-jumbotron {
	display: flex;
	align-items: center;
}

.homepage-jumbotron {
	.big-promise {
		// padding: $HomePage-big-promise-padding;
		background-color: $HomePage-big-promise-background-color;
		color: $HomePage-big-promise-color;
		text-shadow: $HomePage-big-promise-text-shadow;
		min-height: $HomePage-big-promise-min-height;
		width: 100%;

		.backdrop {
			position: absolute;
			width:100%;
			height:100%;
			background-color:rgba(0,0,0,0.5);
			z-index: 1;
		}

		h1 {
			position: relative;
			z-index: 2;
			font-weight: bold;
			font-size:55px;
			margin:0 auto;
			line-height: 1.1em;
			top: 50%;
    			transform: translateY(-50%);
		}
	}
}


#link-card-buttons {
	margin-top: -4rem;
	padding: 3rem 1rem 1rem 1rem;
	background: transparent;
	position: relative;
	z-index: 3;
}

#why-choose-evs {
	padding: 0rem 1rem;
}

.big-promise-caption {
	top: 0;
	left: 0;
	position: absolute;
	padding: 1.5rem 1rem;
}

#HomepageVehiclesCarousel {
	h2 {
		text-align: center;
		font-size:35px;
	}

	p {
		font-size:16px;
		text-align: center;
		max-width:600px;
		margin:0 auto;
	}

	a.btn {
		margin: 25px auto 20px !important;
		display: block;
		max-width: 280px;
		padding:10px 30px;
		border-radius: 3px;
	}

	.EVCard {
		.EVCardTop {
			p {
				text-align: left;

				&.h2 {
					font-size:16px;
				}

				&.h3 {
					font-size:12px;
				}
			}

			.renderRowOfData {
				p {
					font-size: 12px;
				}
			}
		}

		.EVCardBottomWithLink {
			a.btn {
				margin-bottom:0 !important;
			}
		}
	}
}

#HomepageIncentives {
	padding:50px 0;
	background-color:#F2F2F2;

	h3 {
		text-align: center;
		font-size:35px;
	}

	p.lead {
		font-size:16px;
		text-align: center;
		max-width:600px;
		margin:0 auto;

		br {
			display:none;
		}
	}

	a.btn {
		margin: 25px auto 20px !important;
		display: block;
		max-width: 280px;
		padding:10px 30px;
		border-radius: 3px;
		background-color: #465A65;
	}
}